<template>
	<div v-loading="loading" class="input">
		<el-tabs
			tab-position="top"
			v-model="activeName"
			style="margin-left:10px"
		>
			<el-tab-pane
				label="酒店设置"
				name="hotel"
				v-if="authe.Hotel_SetUp_HotelSet_HotelSettingsPage"
			>
				<div v-if="authe.Hotel_SetUp_HotelSet_HotelInformation">
					<!--酒店信息-->
					<el-divider content-position="left">酒店信息</el-divider>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item label="对外简称">
								<el-input
									v-model="basics.jsrsuWxgzhTitle"
									clearable
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
							<el-form-item label="客服电话">
								<el-input
									v-model="basics.jsrsuTelphone"
									oninput="value=value.replace(/[^\d]/g,'')"
									clearable
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
							<el-form-item
								label="公众号首页顶部广告"
								label-width="132px"
							>
								<el-button @click="weixinPicture" size="mini"
									>上传及查看图片</el-button
								>
							</el-form-item>
							<el-dialog
								title="微信展示照片"
								:visible.sync="weixin.dialogPicture"
								@close="closeWeixinDialogPicture"
								:close-on-click-modal="false"
								width="660px"
							>
								<el-upload
									action="https://upload-z2.qiniup.com"
									:data="weixin.pictureData"
									list-type="picture-card"
									:on-success="weixinUploadSuccess"
									:on-error="weixinUploadError"
									:on-preview="weixinPictruePreview"
									:before-remove="weixinBeforeRemove"
									:on-remove="weixinAfterRemove"
									:file-list="weixin.pictureList"
								>
									<i class="el-icon-plus"></i>
								</el-upload>
							</el-dialog>
							<el-dialog :visible.sync="weixin.dialogVisible">
								<img
									width="100%"
									:src="weixin.imageUrl"
									alt=""
								/>
							</el-dialog>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_BasicSettings">
					<!--基础设置-->
					<el-divider content-position="left">基础设置</el-divider>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item label="入住时间">
								<el-time-select
									v-model="basics.jsrsuCheckInTime"
									:picker-options="{
										start: '00:00',
										step: '00:30',
										end: '23:30',
									}"
									placeholder="选择时间"
									size="mini"
									class="screenInput"
									clearable
								>
								</el-time-select>
							</el-form-item>
							<el-form-item label="退房时间">
								<el-time-select
									v-model="basics.jsrsuCheckOutTime"
									:picker-options="{
										start: '00:00',
										step: '00:30',
										end: '23:30',
									}"
									placeholder="选择时间"
									size="mini"
									class="screenInput"
									clearable
								>
								</el-time-select>
							</el-form-item>
							<el-form-item
								label="最长订房时间"
								label-width="95px"
							>
								<el-input
									v-model="basics.jsrsuLongestBookingDays"
									clearable
									size="mini"
									class="screenInput"
									style="margin-top: 6px"
								>
									<template slot="append">天</template>
								</el-input>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="线上下单">
								<el-select
									v-model="basics.jsrsuState"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										label="是"
										:value="1"
									></el-option>
									<el-option
										label="否"
										:value="0"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								label="提前搬离退房费"
								label-width="105px"
							>
								<el-select
									v-model="basics.jsrsuRefundRoomCharge"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										label="允许"
										:value="1"
									></el-option>
									<el-option
										label="不允许"
										:value="0"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								label="提前搬离，超过"
								label-width="105px"
							>
								<el-time-select
									v-model="basics.jsrsuRefundRoomChargeTime"
									:picker-options="{
										start: basics.jsrsuCheckOutTime,
										step: '00:30',
										end: '23:30',
									}"
									@focus="checkTimeChoice"
									placeholder="选择时间"
									size="mini"
									style="width: 122px"
									clearable
								>
								</el-time-select>
								<!--<el-time-picker
                    v-model="basics.jsrsuRefundRoomChargeTime"
                    :picker-options="{
                    selectableRange: '00:00:00 - 23:59:59'
                  }"
                    placeholder="选择时间"
                    format="HH:mm"
                    value-format="HH:mm"
                    clearable
                    size="mini"
                    style="width: 122px">
                </el-time-picker>-->
								<span style="color: #606266"
									>，收取整天房费</span
								>
							</el-form-item>
						</el-row>
						<!--<el-row>
              <el-form-item label="酒店介绍">
                <el-input v-model="basics.jsrsuGrogshopIntroduce" type="textarea" rows="3" size="mini" class="screenInput" style="width: 955px"></el-input>
              </el-form-item>
            </el-row>-->
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_TimeOutHalfDayRule">
					<!--超时半天规则-->
					<el-divider content-position="left"
						>超时半天规则</el-divider
					>
					<el-form
						inline
						:label-width="screenLabelWidth"
						:model="jsrsuTimeoutHalfDayRule.params"
						:rules="jsrsuTimeoutHalfDayRule.rules"
						ref="halfDayPercentage"
					>
						<el-row>
							<el-form-item label="超时时间">
								<el-time-select
									v-model="
										jsrsuTimeoutHalfDayRule.params.halfADay
									"
									:picker-options="{
										start: basics.jsrsuCheckOutTime,
										step: '00:30',
										end: basics.jsrsuCheckInTime,
									}"
									placeholder="选择时间"
									size="mini"
									style="width: 122px"
								>
								</el-time-select>
							</el-form-item>
							<el-form-item label="支付规则">
								<el-radio-group
									v-model="
										jsrsuTimeoutHalfDayRule.params.type
									"
									@change="
										jsrsuTimeoutHalfDayRule.params.number =
											''
									"
								>
									<el-radio :label="0">固定金额</el-radio>
									<el-radio :label="1">房费百分比</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item prop="number">
								<el-input
									v-model="
										jsrsuTimeoutHalfDayRule.params.number
									"
									oninput="value=value.replace(/[^\d]/g,'')"
									size="mini"
									style="margin-left: 20px;width: 80px"
								></el-input>
								<span
									style="color: #606266"
									v-if="
										jsrsuTimeoutHalfDayRule.params.type == 0
									"
								>
									元</span
								>
								<span
									style="color: #606266"
									v-else-if="
										jsrsuTimeoutHalfDayRule.params.type == 1
									"
								>
									% 房费</span
								>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_TimeOutAllDayRule">
					<!--超时整天规则-->
					<el-divider content-position="left"
						>超时整天规则</el-divider
					>
					<el-form
						inline
						:label-width="screenLabelWidth"
						:model="jsrsuTimeoutAllDayRule.params"
						:rules="jsrsuTimeoutAllDayRule.rules"
						ref="allDayPercentage"
					>
						<el-row>
							<el-form-item label="超时时间">
								<el-time-select
									v-model="
										jsrsuTimeoutAllDayRule.params.allDay
									"
									:picker-options="{
										start:
											jsrsuTimeoutHalfDayRule.params
												.halfADay == null
												? basics.jsrsuCheckOutTime
												: jsrsuTimeoutHalfDayRule.params
														.halfADay,
										step: '00:30',
										end: basics.jsrsuCheckInTime,
									}"
									placeholder="选择时间"
									size="mini"
									style="width: 122px"
								>
								</el-time-select>
							</el-form-item>
							<el-form-item label="支付规则">
								<el-radio-group
									v-model="jsrsuTimeoutAllDayRule.params.type"
									@change="
										jsrsuTimeoutAllDayRule.params.number =
											''
									"
								>
									<el-radio :label="0">固定金额</el-radio>
									<el-radio :label="1">房费百分比</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item prop="number">
								<el-input
									v-model="
										jsrsuTimeoutAllDayRule.params.number
									"
									oninput="value=value.replace(/[^\d]/g,'')"
									size="mini"
									style="margin-left: 20px;width: 80px"
								></el-input>
								<span
									style="color: #606266"
									v-if="
										jsrsuTimeoutAllDayRule.params.type == 0
									"
								>
									元</span
								>
								<span
									style="color: #606266"
									v-else-if="
										jsrsuTimeoutAllDayRule.params.type == 1
									"
								>
									% 房费</span
								>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_HourlyRoomUsageRules">
					<!--钟点房使用规则-->
					<el-divider content-position="left"
						>钟点房使用规则</el-divider
					>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item label="使用时间">
								<el-time-picker
									v-model="jsrsuHourRoom.hourRoomStartTime"
									:picker-options="{
										selectableRange: '00:00:00 - 23:59:59',
									}"
									placeholder="开始时间"
									format="HH:mm"
									value-format="HH:mm"
									clearable
									size="mini"
									class="screenInput"
								>
								</el-time-picker
								>&nbsp;&nbsp;-- --&nbsp;
								<el-time-picker
									v-model="jsrsuHourRoom.hourRoomEndTime"
									:picker-options="{
										selectableRange: '00:00:00 - 23:59:59',
									}"
									placeholder="结束时间"
									format="HH:mm"
									value-format="HH:mm"
									clearable
									size="mini"
									class="screenInput"
								>
								</el-time-picker>
							</el-form-item>
							<el-form-item label="钟点房">
								<el-select
									v-model="jsrsuHourRoom.hourRoom"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										label="1小时"
										value="1"
									></el-option>
									<el-option
										label="2小时"
										value="2"
									></el-option>
									<el-option
										label="3小时"
										value="3"
									></el-option>
									<el-option
										label="4小时"
										value="4"
									></el-option>
									<el-option
										label="5小时"
										value="5"
									></el-option>
									<el-option
										label="6小时"
										value="6"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_DepositPaymentRules">
					<!--押金支付规则-->
					<el-divider content-position="left"
						>押金支付规则</el-divider
					>
					<el-form
						inline
						:label-width="screenLabelWidth"
						:model="deposit"
						:rules="deposit.rules"
						ref="deposit"
					>
						<el-row>
							<el-form-item label="支付方式">
								<el-radio-group
									v-model="deposit.jsrsuDepositRules"
									@change="changeDepositRules"
									style="margin-left: 5px"
								>
									<el-radio :label="0">线上支付</el-radio>
									<el-radio :label="1">现场支付</el-radio>
								</el-radio-group>
							</el-form-item>
							<el-form-item
								label="在线定金比"
								label-width="90px"
								prop="jsrsuRoomChargePercent"
							>
								<el-input
									v-model="deposit.jsrsuRoomChargePercent"
									oninput="value=value.replace(/[^\d]/g,'')"
									size="mini"
									style="width:70px"
									:disabled="deposit.disabled"
								></el-input>
								<span style="color: #606266"> % 房费</span>
							</el-form-item>
							<el-form-item label="支付方式">
								<el-checkbox
									v-model="jsrsuDepositSetType.type"
									:true-label="1"
									:false-label="0"
									style="margin-left: 5px"
									>手动设置押金</el-checkbox
								>
							</el-form-item>
							<el-form-item label="押金金额">
								<el-input
									v-model="jsrsuDepositSetType.depositMoney"
									:disabled="!jsrsuDepositSetType.type"
									clearable
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_RoomTypeSetting">
					<!--房型设置-->
					<el-divider content-position="left"
						>房型设置
						<el-popover
							v-model="layout.popoverAddLayout"
							@after-leave="closeLayoutPopover"
							placement="right"
						>
							<el-form
								label-width="75px"
								:model="layout"
								:rules="layout.rules"
								ref="layoutForm"
							>
								<el-form-item label="房型" prop="roomType">
									<el-input
										v-model="layout.roomType"
										clearable
										size="mini"
										class="screenInput"
									></el-input>
								</el-form-item>
								<el-form-item label="可住人数" prop="number">
									<el-input
										v-model="layout.number"
										oninput="value=value.replace(/[^\d]/g,'')"
										clearable
										size="mini"
										class="screenInput"
									></el-input>
								</el-form-item>
							</el-form>
							<div style="text-align: right; margin: 0">
								<el-button
									@click="layout.popoverAddLayout = false"
									size="mini"
									>取消</el-button
								>
								<el-button
									type="primary"
									size="mini"
									@click="addLayout"
									>确定</el-button
								>
							</div>
							<el-button
								slot="reference"
								type="primary"
								icon="el-icon-plus"
								plain
								circle
								size="mini"
								style="margin-left: 5px"
							></el-button>
						</el-popover>
					</el-divider>
					<el-form class="roomType" label-width="35px">
						<el-row>
							<el-form-item
								label="房型"
								v-for="(value, i) in layout.jsrsuRoomType"
								:key="i"
							>
								<el-input
									v-model="value.roomType"
									clearable
									size="mini"
									class="screenInput"
									style="margin-right: 10px"
								></el-input>
								<el-input
									v-model="value.number"
									size="mini"
									class="screenInput"
									style="margin: 7px 10px 0 0"
								>
									<template slot="prepend">可住</template>
									<template slot="append">人</template>
								</el-input>
								<el-button @click="configure(value)" size="mini"
									>房型配置</el-button
								>
								<el-button @click="picture(value)" size="mini"
									>上传及查看图片</el-button
								>
								<el-button
									type="danger"
									@click="deletezLayout(value)"
									icon="el-icon-delete"
									plain
									circle
									size="mini"
								></el-button>
							</el-form-item>
						</el-row>
					</el-form>
					<el-dialog
						:title="`${layout.title}的配置`"
						:visible.sync="layout.dialogConfigure"
						@close="closeDialogConfigure"
						:close-on-click-modal="false"
						width="940px"
					>
						<el-button
							:type="value.type"
							@click="buttonClick(value)"
							v-for="(value, i) in layout.buttonArray"
							:key="i"
							size="mini"
							style="width: 90px;margin: 0 10px 5px 0"
							>{{ value.name }}</el-button
						>
						<div slot="footer" style="text-align: center">
							<el-button @click="layout.dialogConfigure = false"
								>取 消</el-button
							>
							<el-button type="primary" @click="addConfigureSave"
								>保 存</el-button
							>
						</div>
					</el-dialog>
					<el-dialog
						:title="`${layout.title}的照片`"
						:visible.sync="layout.dialogPicture"
						@close="closeDialogPicture"
						:close-on-click-modal="false"
						width="660px"
					>
						<el-upload
							action="https://upload-z2.qiniup.com"
							:data="layout.pictureData"
							list-type="picture-card"
							:on-success="uploadSuccess"
							:on-error="uploadError"
							:on-preview="pictruePreview"
							:before-remove="beforeRemove"
							:on-remove="afterRemove"
							:limit="1"
							:on-exceed="afterExceed"
							:file-list="layout.pictureList"
						>
							<i class="el-icon-plus"></i>
						</el-upload>
					</el-dialog>
					<el-dialog :visible.sync="layout.dialogVisible">
						<img width="100%" :src="layout.imageUrl" alt="" />
					</el-dialog>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_DepositPaymentRules">
					<!--自动排房规则-->
					<el-divider content-position="left"
						>自动排房规则</el-divider
					>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item>
								<el-radio-group
									v-model="jsrsuAutoRowroomRules"
									@change="ChangeTheRowRoomRules"
									style="margin-left: 5px"
								>
									<el-radio :label="1">集中性排房</el-radio>
									<el-radio :label="2"
										>平均入住率排房</el-radio
									>
									<el-radio :label="3"
										>平均楼层率排房</el-radio
									>
								</el-radio-group>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_EquipmentSetting">
					<!--设备设置-->
					<el-divider content-position="left">设备设置</el-divider>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item
								label="客房密码设置"
								label-width="90px"
							>
								<el-input
									v-model="numberPlate.jsrsuTmPassword"
									clearable
									show-password
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
							<el-form-item
								label="红外检测时间"
								label-width="90px"
							>
								<el-select
									v-model="
										numberPlate.jsrsuInfraredMonitoringTime
									"
									size="mini"
									class="screenInput"
								>
									<el-option
										label="10分钟"
										:value="10"
									></el-option>
									<el-option
										label="20分钟"
										:value="20"
									></el-option>
									<el-option
										label="30分钟"
										:value="30"
									></el-option>
								</el-select>
							</el-form-item>
							<el-form-item
								label="启动语音前台服务"
								label-width="120px"
							>
								<el-switch
									v-model="numberPlate.jsrsuVoiceService"
									:active-value="1"
									:inactive-value="0"
								></el-switch>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item
								v-for="(value, i) in this
									.jsrsuElectronicDoorplateno"
								:key="i"
								:label="`按键${value.keyNumber + 1}`"
								label-width="90px"
							>
								<el-select
									v-model="value.keyName"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										label="请勿打扰"
										value="请勿打扰"
									></el-option>
									<el-option
										label="已入住"
										value="已入住"
									></el-option>
									<el-option
										label="保洁服务"
										value="保洁服务"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div
					v-if="authe.Hotel_SetUp_HotelSet_PlatformSettlementAccount"
				>
					<!--平台结算账户-->
					<el-divider content-position="left"
						>平台结算账户</el-divider
					>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item label="收款账户">
								<el-select
									v-model="hotelAccountNumber.faPaymentType"
									@change="
										faPaymentTypeChange(
											hotelAccountNumber,
											'hotel',
											true
										)
									"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										v-for="(value, i) in acountType"
										:key="i"
										:label="value.variable"
										:value="value.variable"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="账户名称">
								<el-select
									v-model="hotelAccountNumber.faId"
									@change="
										faUserNameChange(hotelAccountNumber)
									"
									:disabled="
										!hotelAccountNumber.faPaymentType
									"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										v-for="(value, i) in hotelAcountName"
										:key="i"
										:label="value.faUserName"
										:value="value.faId"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="账户归属">
								<el-input
									v-model="hotelAccountNumber.faBelonging"
									disabled
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
							<el-form-item label="账户号码">
								<el-input
									v-model="hotelAccountNumber.faAccount"
									disabled
									size="mini"
									style="width: 155px"
								></el-input>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_CashSettlementAccount">
					<!--现金结算账户-->
					<el-divider content-position="left"
						>现金结算账户</el-divider
					>
					<el-form inline :label-width="screenLabelWidth">
						<el-row>
							<el-form-item label="收款账户">
								<el-select
									v-model="cashAccount.faPaymentType"
									@change="
										faPaymentTypeChange(
											cashAccount,
											'cash',
											true
										)
									"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										v-for="(value, i) in acountType"
										:key="i"
										:label="value.variable"
										:value="value.variable"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="账户名称">
								<el-select
									v-model="cashAccount.faId"
									@change="faUserNameChange(cashAccount)"
									:disabled="!cashAccount.faPaymentType"
									size="mini"
									class="screenInput"
								>
									<el-option label="" value=""></el-option>
									<el-option
										v-for="(value, i) in cashAcountName"
										:key="i"
										:label="value.faUserName"
										:value="value.faId"
									></el-option>
								</el-select>
							</el-form-item>
						</el-row>
						<el-row>
							<el-form-item label="账户归属">
								<el-input
									v-model="cashAccount.faBelonging"
									disabled
									size="mini"
									class="screenInput"
								></el-input>
							</el-form-item>
							<el-form-item label="账户号码">
								<el-input
									v-model="cashAccount.faAccount"
									disabled
									size="mini"
									style="width: 155px"
								></el-input>
							</el-form-item>
						</el-row>
					</el-form>
				</div>
				<div v-if="authe.Hotel_SetUp_HotelSet_ServiceConsumption">
					<!--服务消费-->
					<el-divider content-position="left"
						>服务消费
						<el-popover
							@after-leave="closeTablePopover"
							placement="right"
							v-model="table.popoverAddService"
						>
							<el-form
								label-width="50px"
								:model="table"
								:rules="table.rules"
								ref="tableForm"
							>
								<el-form-item label="服务" prop="popservice">
									<el-input
										v-model="table.popservice"
										clearable
										size="mini"
										class="screenInput"
									></el-input>
								</el-form-item>
								<el-form-item label="金额" prop="popcharge">
									<el-input
										v-model="table.popcharge"
										oninput="value=value.replace(/[^\d]/g,'')"
										clearable
										size="mini"
										class="screenInput"
									></el-input>
								</el-form-item>
							</el-form>
							<div style="text-align: right; margin: 0">
								<el-button
									@click="table.popoverAddService = false"
									size="mini"
									>取消</el-button
								>
								<el-button
									type="primary"
									size="mini"
									@click="addService"
									>确定</el-button
								>
							</div>
							<el-button
								slot="reference"
								type="primary"
								icon="el-icon-plus"
								plain
								circle
								size="mini"
								style="margin-left: 5px"
							></el-button>
						</el-popover>
					</el-divider>
					<el-row>
						<el-table
							:data="table.dataTable"
							highlight-current-row
							height="315px"
							style="width: 100%;"
						>
							<el-table-column
								prop="popservice"
								label="服务"
								min-width="100px"
							></el-table-column>
							<el-table-column
								prop="popcharge"
								label="金额"
								min-width="100px"
							></el-table-column>
							<el-table-column label="删除" min-width="100px">
								<template slot-scope="scope">
									<el-button
										type="danger"
										@click="deleteService(scope.row)"
										icon="el-icon-delete"
										plain
										size="mini"
										>删除</el-button
									>
								</template>
							</el-table-column>
						</el-table>
					</el-row>
				</div>
				<div>
					<el-divider content-position="left">轮播图设置</el-divider>
					<el-row>
						<div class="center">
								<div>轮播图链接1(375*150):</div>
								<div><el-input placeholder="https://baidu.com" v-model="carouselMapLink1" style="width:380px" size="small"/></div>
						</div>
						<div class="center">
							<div>轮播图链接2(375*150):</div>
							<div><el-input placeholder="https://baidu.com" v-model="carouselMapLink2" style="width:380px" size="small"/></div>
						</div>
						<div class="center">
							<div>轮播图链接3(375*150):</div>
							<div><el-input placeholder="https://baidu.com" v-model="carouselMapLink3" style="width:380px" size="small"/></div>
						</div>
					</el-row>
				</div>
				<div
					v-if="authe.Hotel_SetUp_HotelSet_HotelSetUpSave"
					style="text-align:center;margin-top: 15px"
				>
					<!--保 存-->
					<el-button type="primary" @click="save">保 存</el-button>
				</div>
			</el-tab-pane>
			<el-tab-pane
				label="设备设置"
				name="equipment"
				v-if="authe.Hotel_SetUp_HotelSet_EquipmentSettingPage"
			>
				<div class="input Part">
					<el-button
						@click="putChangeEquip"
						v-if="authe.Hotel_SetUp_HotelSet_HotelSaveBtn"
						size="mini"
						type="primary"
						>保存</el-button
					>
					<el-button
						@click="recoveryEquip"
						v-if="authe.Hotel_SetUp_HotelSet_HotelRecoveryBtn"
						size="mini"
						type="primary"
						>恢复初始</el-button
					>
					&nbsp;&nbsp;&nbsp;&nbsp;<span style="color:red"
						>注：仅灰色背景项支持恢复初始操作</span
					>
				</div>
				<el-table
					border
					:data="setupEquip"
					@selection-change="setupEquipChange"
					fit
					highlight-current-row
					:height="tableHeight"
					:row-class-name="rowStyle"
				>
					<el-table-column
						align="center"
						type="selection"
						width="55"
						:selectable="selectable"
					></el-table-column>
					<el-table-column
						prop="name"
						label="类型"
						width="300px"
					></el-table-column>
					<el-table-column show-overflow-tooltip label="用途">
						<template slot-scope="scope">
							<span style="color:red">{{
								scope.row.useType
							}}</span>
						</template>
					</el-table-column>
					<el-table-column align="center" label="输入框">
						<template slot-scope="scope">
							<div>
								<el-input
									size="mini"
									v-model="scope.row.on"
									style="width:200px"
									v-if="scope.row.input1 == 1"
								></el-input>
								<el-select
									size="mini"
									v-model="scope.row.on"
									style="width:200px"
									v-if="scope.row.input1 == 2"
								>
									<el-option label="2" value="2"></el-option>
									<el-option label="4" value="4"></el-option>
									<el-option label="8" value="8"></el-option>
									<el-option
										label="10"
										value="10"
									></el-option>
									<el-option
										label="16"
										value="16"
									></el-option>
								</el-select>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
		<!-- <button @click="hhh">hhh</button> -->
	</div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
	name: 'hotelSettings',
	data() {
		let roomTypeName = (rule, value, callback) => {
			for (let i of this.layout.jsrsuRoomType) {
				if (value == i.roomType) {
					callback(new Error('房型名称不能重复'))
				}
			}
		}
		let jsrsuRoomChargePercent = (rule, value, callback) => {
			if (value > 100) {
				callback(new Error('房费百分比不能大于 100 哦'))
			}
		}
		let halfDayPercentage = (rule, value, callback) => {
			if (this.jsrsuTimeoutHalfDayRule.params.type == 1 && value > 100) {
				callback(new Error('房费百分比不能大于 100 哦'))
			}
		}
		let allDayPercentage = (rule, value, callback) => {
			if (this.jsrsuTimeoutAllDayRule.params.type == 1 && value > 100) {
				callback(new Error('房费百分比不能大于 100 哦'))
			}
		}
		let popserviceName = (rule, value, callback) => {
			for (let i of this.table.dataTable) {
				if (value == i.popservice) {
					callback(new Error('服务名称不能重复'))
				}
			}
		}
		return {
			loading: false,
			activeName: 'hotel',
			tableHeight: window.innerHeight - 230,
			screenLabelWidth: '65px',
			basics: {
				//基础设置
				jsrsuCheckInTime: '',
				jsrsuCheckOutTime: '',
				jsrsuWxgzhTitle: '',
				jsrsuTelphone: '',
				jsrsuLongestBookingDays: '',
				jsrsuState: '',
				jsrsuRefundRoomCharge: '',
				jsrsuRefundRoomChargeTime: '',
				jsrsuFutureBookingDays: '',
				jsrsuGrogshopIntroduce: '',
			},
			jsrsuAdImgs: [], //微信图片
			weixin: {
				//微信图片
				dialogPicture: false,
				dialogVisible: false,
				pictureData: {
					//上传图片携带的参数
					token: '',
				},
				pictureList: [],
				imageUrl: '',
			},
			jsrsuHourRoom: {}, //钟点房使用规则
			deposit: {
				//押金支付规则上
				disabled: false,
				jsrsuDepositRules: '',
				jsrsuRoomChargePercent: '',
				rules: {
					jsrsuRoomChargePercent: [
						{
							required: true,
							message: '在线定金比不能为空',
							trigger: 'blur',
						},
						{ validator: jsrsuRoomChargePercent, trigger: 'blur' },
						{
							min: 1,
							max: 10,
							message: '在线定金比长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
			},

			jsrsuDepositSetType: {}, //押金支付规则下
			jsrsuAutoRowroomRules: '',
			layout: {
				//房型设置
				popoverAddLayout: false, //添加房型弹出框
				roomType: '', //添加房型input
				number: '', //添加房型人数input
				rules: {
					roomType: [
						{
							required: true,
							message: '房型名称不能为空',
							trigger: 'blur',
						},
						{ validator: roomTypeName, trigger: 'blur' },
						{
							min: 1,
							max: 10,
							message: '房型名称长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
					number: [
						{
							required: true,
							message: '可住人数不能为空',
							trigger: 'blur',
						},
						{
							min: 1,
							max: 10,
							message: '可住人数长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
				jsrsuRoomType: [],
				title: '',
				row: '',
				//房型配置
				dialogConfigure: false,
				buttonArray: [
					//按钮数组
					{
						name: '热水淋浴',
						type: '',
					},
					{
						name: '无线网络',
						type: '',
					},
					{
						name: '空调',
						type: '',
					},
					{
						name: '电视',
						type: '',
					},
					{
						name: '门禁系统',
						type: '',
					},
					{
						name: '停车位',
						type: '',
					},
					{
						name: '热水壶',
						type: '',
					},
					{
						name: '有线网络',
						type: '',
					},
					{
						name: '电脑',
						type: '',
					},
					{
						name: '拖鞋',
						type: '',
					},
					{
						name: '纸巾',
						type: '',
					},
					{
						name: '牙具',
						type: '',
					},
					{
						name: '毛巾',
						type: '',
					},
					{
						name: '溶液',
						type: '',
					},
					{
						name: '洗发水',
						type: '',
					},
					{
						name: '香皂',
						type: '',
					},
					{
						name: '浴巾',
						type: '',
					},
					{
						name: '剃须刀',
						type: '',
					},
					{
						name: '吹风筒',
						type: '',
					},
					{
						name: '适宜儿童',
						type: '',
					},
					{
						name: '适宜老人',
						type: '',
					},
					{
						name: '适宜残疾人',
						type: '',
					},
					{
						name: '电梯',
						type: '',
					},
					{
						name: '洗衣机',
						type: '',
					},
					{
						name: '冰箱',
						type: '',
					},
					{
						name: '浴缸',
						type: '',
					},
					{
						name: '暖气',
						type: '',
					},
				],
				//图片上传
				dialogPicture: false,
				dialogVisible: false,
				pictureData: {
					//上传图片携带的参数
					token: '',
				},
				pictureList: [],
				imageUrl: '',
			},
			numberPlate: {
				//电子门牌设置上
				jsrsuTmPassword: '',
				jsrsuInfraredMonitoringTime: 10,
				jsrsuVoiceService: '',
			},
			jsrsuElectronicDoorplateno: {}, //电子门牌设置下
			acountType: [], //结算账户列表
			hotelAcountName: [], //平台结算名称列表
			cashAcountName: [], //现金结算名称列表
			hotelAccountNumber: {}, //平台结算账户
			cashAccount: {}, //现金结算账户
			table: {
				//服务消费
				popservice: '',
				popcharge: '',
				dataTable: [],
				popoverAddService: false,
				rules: {
					popservice: [
						{
							required: true,
							message: '服务名称不能为空',
							trigger: 'blur',
						},
						{ validator: popserviceName, trigger: 'blur' },
						{
							min: 1,
							max: 10,
							message: '服务名称长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
					popcharge: [
						{
							required: true,
							message: '金额不能为空',
							trigger: 'blur',
						},
						{
							min: 1,
							max: 10,
							message: '金额名称长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
			},
			jsrsuTimeoutHalfDayRule: {
				params: {
					halfADay: '',
					number: 0,
					type: 1,
				},
				rules: {
					number: [
						{
							required: true,
							message: '房费不能为空',
							trigger: 'blur',
						},
						{ validator: halfDayPercentage, trigger: 'blur' },
						{
							min: 1,
							max: 10,
							message: '房费长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
			},
			jsrsuTimeoutAllDayRule: {
				params: {
					allDay: '',
					number: 0,
					type: 1,
				},
				rules: {
					number: [
						{
							required: true,
							message: '房费不能为空',
							trigger: 'blur',
						},
						{ validator: allDayPercentage, trigger: 'blur' },
						{
							min: 1,
							max: 10,
							message: '房费长度为 1 到 10 个字符',
							trigger: 'blur',
						},
					],
				},
			},
			verification: ['deposit', 'halfDayPercentage', 'allDayPercentage'],
			//设备设置
			setupEquip: [
				{
					name: '当日水用量高于昨日用量预警变量',
					on: '',
					type: 'waterDailyVariable',
					useType:
						'当日用水量高于昨日用量【设置用量】立方时，进入预警提醒',
					recovery: 1,
					input1: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
				{
					name: '空置用水预警变量',
					text: '',
					type2: 'waterVacantVariable',
					useType: '空置用水时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
				},
				{
					name: '空置用电预警变量',
					text: '',
					type2: 'elecVacantVariable',
					useType: '空置用电时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
				},
				{
					name: '连续时间（分钟）用水预警变量',
					on: '',
					type: 'waterContinuityVariable',
					useType: '连续【设置时间】用水时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
				{
					name: '门卡进制设置',
					on: '',
					type: 'doorCardSystem',
					useType: '取值范围（2、4、8、10、16）用途：设置门卡的进制',
					input1: 2,
					recovery: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
			],
			selEquipList: [],
			carouselMapLink1:'',
			carouselMapLink2:'',
			carouselMapLink3:''
		}
	},
	computed: {
		...mapState(['companyVars', 'authe', 'pageAuth', 'setupList']),
	},
	created() {
		if (this.pageAuth['/hotelSettings']) {
			this.dataCreated()
		} else {
			this.$router.replace('/notfind')
		}
	},
	watch: {
		async activeName(tab) {
			console.log(this.setupEquip, this.companyVars)
			this.setupEquip = [
				{
					name: '当日水用量高于昨日用量预警变量',
					on: '',
					type: 'waterDailyVariable',
					useType:
						'当日用水量高于昨日用量【设置用量】立方时，进入预警提醒',
					recovery: 1,
					input1: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
				{
					name: '连续时间（分钟）用水预警变量',
					on: '',
					type: 'waterContinuityVariable',
					useType: '连续【设置时间】用水时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
				{
					name: '空置用水预警变量',
					text: '',
					type2: 'waterVacantVariable',
					useType: '空置用水时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
				},
				{
					name: '空置用电预警变量',
					text: '',
					type2: 'elecVacantVariable',
					useType: '空置用电时，进入预警提醒',
					input1: 1,
					recovery: 1,
					show: 1,
				},
				{
					name: '门卡进制设置',
					on: '',
					type: 'doorCardSystem',
					useType: '取值范围（2、4、8、10、16）用途：设置门卡的进制',
					input1: 2,
					recovery: 1,
					show: 1,
					save: 1,
					switch: 0,
				},
			]
			if (tab == 'equipment') {
				for (let i in this.companyVars) {
					for (let k of this.setupEquip) {
						if (k.type == i) {
							k.on = this.companyVars[i]
						}
					}
				}
			}
		},
	},
	methods: {
		// hhh() {
		// 	this.$axios({
		// 		method: 'get',
		// 		url: '/house4store/autoRowRoom',
		// 	})
		// 		.then((res) => {
		// 			console.log(res)
		// 		})
		// 		.catch((err) => {
		// 			this.isError(err, this)
		// 		})
		// },
		...mapMutations(['getSetupList', 'getBudgetList']),
		...mapActions(['getBudgetList']),
		//数据查询
		async dataCreated() {
			try {
				this.loading = true
				let getData = await this.getData()
				this.getHotelAccount()
				await this.faPaymentTypeChange(
					this.hotelAccountNumber,
					'hotel',
					false
				)
				await this.faPaymentTypeChange(this.cashAccount, 'cash', false)
				await this.getToken()
				this.loading = false
			} catch (err) {
				console.error(err)
				console.log(err)
			}
		},
		getData() {
			//查询数据
			return this.$axios({
				method: 'get',
				url: `/hotel/shortRentSetUps/1`,
			})
				.then((res) => {
					this.data = res.data.result
					for (let i in this.data) {
						for (let j in this.basics) {
							//基础设置
							if (i == j) {
								this.basics[j] = this.data[i]
							}
						}
						for (let j in this.deposit) {
							//押金支付规则上
							if (i == j) {
								this.deposit[j] = this.data[i]
							}
						}
						for (let j in this.numberPlate) {
							//电子门牌设置上
							if (i == j) {
								this.numberPlate[j] = this.data[i]
							}
						}
					}
					console.log(this.data)
					this.jsrsuAdImgs = res.data.result.jsrsuAdImgs
						? JSON.parse(res.data.result.jsrsuAdImgs)
						: []
					this.jsrsuHourRoom = res.data.result.jsrsuHourRoom
						? JSON.parse(res.data.result.jsrsuHourRoom)
						: {}
					this.jsrsuDepositSetType = res.data.result
						.jsrsuDepositSetType
						? JSON.parse(res.data.result.jsrsuDepositSetType)
						: {}
					this.layout.jsrsuRoomType = res.data.result.jsrsuRoomType
						? JSON.parse(res.data.result.jsrsuRoomType)
						: []
					this.jsrsuElectronicDoorplateno = res.data.result
						.jsrsuElectronicDoorplateno
						? JSON.parse(res.data.result.jsrsuElectronicDoorplateno)
						: []
					this.hotelAccountNumber = res.data.result.hotelAccountNumber
						? res.data.result.hotelAccountNumber
						: ''
					this.cashAccount = res.data.result.cashAccount
						? res.data.result.cashAccount
						: ''
					this.table.dataTable = res.data.result.jsrsuServiceCharge
						? JSON.parse(res.data.result.jsrsuServiceCharge)
						: []
					this.jsrsuTimeoutHalfDayRule.params = res.data.result
						.jsrsuTimeoutHalfDayRule
						? JSON.parse(res.data.result.jsrsuTimeoutHalfDayRule)
						: this.jsrsuTimeoutHalfDayRule.params
					this.jsrsuTimeoutAllDayRule.params = res.data.result
						.jsrsuTimeoutAllDayRule
						? JSON.parse(res.data.result.jsrsuTimeoutAllDayRule)
						: this.jsrsuTimeoutAllDayRule.params
					this.jsrsuAutoRowroomRules = res.data.result
						.jsrsuAutoRowroomRules
						? JSON.parse(res.data.result.jsrsuAutoRowroomRules)
						: ''
					this.getSetupList(res.data.result)
					return res.data.result
				})
				.catch((err) => {
					this.isError(err, this)
					// console.log(err)
				})
		},
		async getToken() {
			//上传完文件前获取token
			this.$axios({
				method: 'get',
				url: '/common1/photos/token',
				params: {
					bucket: '公有',
				},
			})
				.then((res) => {
					//获取 token
					console.log(res.data.result)
					this.layout.pictureData.token = res.data.result
					this.weixin.pictureData.token = res.data.result
				})
				.catch((err) => {
					this.isError(err, this)
				})
		},
		//微信展示图片
		weixinPicture() {
			//打开图片上传
			for (let i of this.jsrsuAdImgs) {
				let obj = { url: i.path }
				this.weixin.pictureList.push(obj)
			}
			this.weixin.dialogPicture = true
		},
		weixinUploadSuccess(res, file, fileList) {
			//上传成功后在图片框显示图片
			this.weixin.imageUrl =
				'https://pic-gongkai.fangzhizun.com/' + res.key
			file.url = this.weixin.imageUrl
			this.jsrsuAdImgs.push({ path: this.weixin.imageUrl })
			this.weixin.pictureList = fileList
		},
		weixinUploadError(res) {
			//显示错误
			this.$message({
				showClose: true,
				duration: 6000,
				message: `上传失败，请重新上传`,
				type: 'error',
			})
			this.getToken()
		},
		weixinPictruePreview(file) {
			//查看某张图片的原图
			this.weixin.imageUrl = file.url
			this.weixin.dialogVisible = true
		},
		weixinBeforeRemove(file) {
			//删除前
			return this.$confirm(`确定移除？`)
		},
		weixinAfterRemove(file, fileList) {
			//删除后
			for (let i in this.jsrsuAdImgs) {
				if (this.jsrsuAdImgs[i].path == file.url) {
					this.jsrsuAdImgs.splice(i, 1)
					break
				}
			}
			this.weixin.pictureList = fileList
		},
		closeWeixinDialogPicture() {
			//关闭时清空图片
			this.weixin.row = ''
			this.weixin.title = ''
			this.weixin.pictureList = []
		},
		//房型设置
		addLayout() {
			//添加房型
			if (this.submitForm(['layoutForm'], this)) {
				let obj = {
					roomType: this.layout.roomType,
					number: this.layout.number,
					configurationInfo: '',
					path: '',
				}
				this.layout.jsrsuRoomType.push(obj)
				this.layout.popoverAddLayout = false
			}
		},
		//改变排房规则
		ChangeTheRowRoomRules() {},
		closeLayoutPopover() {
			this.resetForm(['layoutForm'], this)
			this.layout.roomType = ''
		},
		deletezLayout(row) {
			//删除服务
			let arr = []
			this.$axios({
				method: 'get',
				url: `/hotel/shortRentSetUps/isAllowDeleteHouseType?hsType=${row.roomType}`,
			})
				.then((res) => {
					console.log(res.data.result)
					if (res.data.result) {
						for (let i of this.layout.jsrsuRoomType) {
							if (i != row) {
								arr.push(i)
							}
						}
						this.layout.jsrsuRoomType = arr
					} else {
						this.$message({
							message: `该房型已存在房间不能删除`,
							type: 'error',
						})
					}
				})
				.catch((err) => {
					this.isError(err, this)
				})
		},
		//房型配置
		configure(row) {
			//打开房型配置
			this.layout.row = row
			this.layout.title = row.roomType
			if (row.configurationInfo) {
				let arr = []
				arr = row.configurationInfo.split(' ')
				for (let k of arr) {
					for (let j of this.layout.buttonArray) {
						if (k == j.name) {
							j.type = 'primary'
						}
					}
				}
			}
			this.layout.dialogConfigure = true
		},
		buttonClick(row) {
			//点击时切换按钮颜色
			if (row.type == '') {
				row.type = 'primary'
			} else {
				row.type = ''
			}
		},
		addConfigureSave() {
			//修改房型配置
			let arr = []
			for (let i of this.layout.buttonArray) {
				if (i.type == 'primary') {
					arr.push(i.name)
				}
			}
			for (let i of this.layout.jsrsuRoomType) {
				if (i == this.layout.row) {
					i.configurationInfo = arr.join(' ')
				}
			}
			this.layout.dialogConfigure = false
		},
		closeDialogConfigure() {
			//关闭时清空房型配置
			this.layout.row = ''
			this.layout.title = ''
			for (let i of this.layout.buttonArray) {
				i.type = ''
			}
		},
		//图片上传
		picture(row) {
			//打开图片上传
			this.layout.row = row
			this.layout.title = row.roomType
			if (row.path) {
				this.layout.pictureList.push({ url: row.path })
			}
			this.layout.dialogPicture = true
		},
		uploadSuccess(res, file, fileList) {
			//上传成功后在图片框显示图片
			this.layout.imageUrl =
				'https://pic-gongkai.fangzhizun.com/' + res.key
			file.url = this.layout.imageUrl
			this.layout.pictureList = fileList
			for (let i of this.layout.jsrsuRoomType) {
				if (i == this.layout.row) {
					i.path = this.layout.pictureList[0].url
				}
			}
		},
		uploadError(res) {
			//显示错误
			this.$message({
				showClose: true,
				duration: 6000,
				message: `上传失败，请重新上传`,
				type: 'error',
			})
			this.getToken()
		},
		pictruePreview(file) {
			//查看某张图片的原图
			this.layout.imageUrl = file.url
			this.layout.dialogVisible = true
		},
		beforeRemove(file) {
			//删除前
			return this.$confirm(`确定移除？`)
		},
		afterRemove(file, fileList) {
			//删除后
			for (let i in this.layout.jsrsuRoomType) {
				if (this.layout.jsrsuRoomType[i].path == file.url) {
					this.layout.jsrsuRoomType[i].path = ''
					break
				}
			}
			this.layout.pictureList = fileList
		},
		afterExceed(file, fileList) {
			//上传超过限制后
			this.$message.warning('只可上传一张图片,请删除已有照片后重新上传')
		},
		closeDialogPicture() {
			//关闭时清空图片
			this.layout.row = ''
			this.layout.title = ''
			this.layout.pictureList = []
		},
		//结算账户
		getHotelAccount() {
			//查询账户类型
			this.$axios({
				method: 'get',
				url: `common1/companyVar/1`,
			})
				.then((res) => {
					res.data.result.acountType
						? (this.acountType = JSON.parse(
								res.data.result.acountType
						  ))
						: (this.acountType = '')
					console.log(res)
					console.log(res.data.result.doorCardSystem)
					// this.scope.row.on=res.data.result.doorCardSystem
					for (let i in this.setupEquip) {
						if (this.setupEquip[i].input1 == '2') {
							console.log('进入了')
							this.setupEquip[i].on = Number(
								res.data.result.doorCardSystem
							)
						}
					}
					let yijiayinPhoto = res.data.result.yijiayinPhoto.split(',')
					console.log(yijiayinPhoto)
					this.carouselMapLink1 = yijiayinPhoto[0]
					this.carouselMapLink2 = yijiayinPhoto[1]
					this.carouselMapLink3 = yijiayinPhoto[2]
				})
				.catch((err) => {
					// this.isError(err,this)
					console.log(err)
				})
		},
		async faPaymentTypeChange(object, string, bool) {
			//账户类型改变时
			if (object.faPaymentType) {
				this.$axios({
					method: 'get',
					url: '/hotel/bankAccounts',
					params: {
						faPaymentType: object.faPaymentType,
					},
				})
					.then((res) => {
						if (bool) {
							for (let i in object) {
								if (i != 'faPaymentType') {
									object[i] = ''
								}
							}
							object.faId = null
						}
						if (string == 'hotel') {
							this.hotelAcountName = res.data.result
						} else if (string == 'cash') {
							this.cashAcountName = res.data.result
						}
					})
					.catch((err) => {
						this.isError(err, this)
					})
			} else {
				for (let i in object) {
					object[i] = ''
				}
				object.faId = null
				if (string == 'hotel') {
					this.hotelAcountName = []
				} else if (string == 'cash') {
					this.cashAcountName = []
				}
			}
		},
		faUserNameChange(object) {
			//账户名称改变时
			if (object.faId) {
				object.faBelonging = ''
				object.faAccount = ''
				this.$axios({
					method: 'get',
					url: '/hotel/bankAccounts',
					params: {
						faPaymentType: object.faPaymentType,
						faId: object.faId,
					},
				})
					.then((res) => {
						object.faBelonging = res.data.result[0].faBelonging
						object.faAccount = res.data.result[0].faAccount
					})
					.catch((err) => {
						this.isError(err, this)
					})
			} else {
				for (let i in object) {
					if (i != 'faPaymentType') {
						object[i] = ''
					}
				}
				object.faId = null
			}
		},
		//服务消费
		addService() {
			//添加服务
			if (this.submitForm(['tableForm'], this)) {
				let obj = {
					popservice: this.table.popservice,
					popcharge: this.table.popcharge,
				}
				this.table.dataTable.push(obj)
				this.table.popoverAddService = false
			}
		},
		closeTablePopover() {
			this.resetForm(['tableForm'], this)
			this.table.popservice = ''
			this.table.popcharge = ''
		},
		deleteService(row) {
			//删除服务
			let arr = []
			for (let i of this.table.dataTable) {
				if (i != row) {
					arr.push(i)
				}
			}
			this.table.dataTable = arr
		},
		//保存
		save() {
			if (!this.submitForm(this.verification, this)) {
				this.$message.warning('请检查必填项以及是否填写正确')
				return
			}
			let jsrsuDepositSetType = {
				type: this.jsrsuDepositSetType.type,
				depositMoney: this.jsrsuDepositSetType.depositMoney,
			}
			let shortRentSetUpPARM = {
				jsrsuAdImgs: JSON.stringify(this.jsrsuAdImgs),
				jsrsuCashAccount: this.cashAccount.faId,
				jsrsuCheckInTime: this.basics.jsrsuCheckInTime,
				jsrsuCheckOutTime: this.basics.jsrsuCheckOutTime,
				jsrsuDepositRules: this.deposit.jsrsuDepositRules,
				jsrsuDepositSetType: JSON.stringify(jsrsuDepositSetType),
				jsrsuElectronicDoorplateno: JSON.stringify(
					this.jsrsuElectronicDoorplateno
				),
				jsrsuFutureBookingDays: this.basics.jsrsuFutureBookingDays,
				jsrsuGrogshopIntroduce: this.basics.jsrsuGrogshopIntroduce,
				jsrsuHourRoom: JSON.stringify(this.jsrsuHourRoom),
				jsrsuLongestBookingDays: this.basics.jsrsuLongestBookingDays,
				jsrsuRefundRoomCharge: this.basics.jsrsuRefundRoomCharge,
				jsrsuRefundRoomChargeTime: this.basics
					.jsrsuRefundRoomChargeTime,
				jsrsuRoomChargePercent: this.deposit.jsrsuRoomChargePercent,
				jsrsuRoomType: JSON.stringify(this.layout.jsrsuRoomType),
				jsrsuServiceCharge: JSON.stringify(this.table.dataTable),
				jsrsuShopAccount: this.hotelAccountNumber.faId,
				jsrsuState: this.basics.jsrsuState,
				jsrsuTelphone: this.basics.jsrsuTelphone,
				jsrsuTmPassword: this.numberPlate.jsrsuTmPassword,
				jsrsuInfraredMonitoringTime: this.numberPlate
					.jsrsuInfraredMonitoringTime,
				jsrsuVoiceService: this.numberPlate.jsrsuVoiceService,
				jsrsuWxgzhTitle: this.basics.jsrsuWxgzhTitle,
				jsrsuTimeoutHalfDayRule: JSON.stringify(
					this.jsrsuTimeoutHalfDayRule.params
				),
				jsrsuTimeoutAllDayRule: JSON.stringify(
					this.jsrsuTimeoutAllDayRule.params
                ),
                jsrsuAutoRowroomRules:this.jsrsuAutoRowroomRules,
				yijiayinPhoto:`${this.carouselMapLink1},${this.carouselMapLink2},${this.carouselMapLink3}`
			}
			console.log(JSON.stringify(this.deposit))
			console.log(JSON.stringify(this.jsrsuDepositSetType))
			// console.log(shortRentSetUpPARM);
			// return
			this.$axios({
				method: 'put',
				url: `/hotel/shortRentSetUps/${this.data.jsrsuId}`,
				data: shortRentSetUpPARM,
			})
				.then((res) => {
					this.$message.success('修改成功')
					this.getData()
				})
				.catch((err) => {
					this.isError(err, this)
					// console.log(err)
				})
		},
		changeDepositRules() {
			if (this.deposit.jsrsuDepositRules == 0) {
				this.deposit.disabled = true
				this.deposit.jsrsuRoomChargePercent = 100.0
			} else {
				this.deposit.disabled = false
			}
		},
		checkTimeChoice() {
			if (
				this.basics.jsrsuCheckOutTime == null ||
				this.basics.jsrsuCheckOutTime == '' ||
				this.basics.jsrsuCheckInTime == null ||
				this.basics.jsrsuCheckInTime == ''
			) {
				this.$message.warning('请先设置入住时间和退房时间!')
			} else if (
				this.basics.jsrsuCheckOutTime == null ||
				this.basics.jsrsuCheckOutTime == ''
			) {
				this.$message.warning('请先设置退房时间!')
			} else if (
				this.basics.jsrsuCheckInTime == null ||
				this.basics.jsrsuCheckInTime == ''
			) {
				this.$message.warning('请先设置入住时间!')
			}
		},
		//设备设置
		rowStyle({ row, rowIndex }) {
			if (row.show != 1) {
				return 'hidden-row'
			}
			if (row.recovery == 1) {
				console.log(row.recovery)
				return 'info-row'
			}
		},
		selectable(row) {
			if (row.recovery == 1) {
				return true //禁用状态
			} else {
				return false //非禁用状态
			}
		},
		setupEquipChange(val) {
			//勾选
			this.selEquipList = val
		},
		putChangeEquip() {
			//保存
			let obj = {}
			this.setupEquip.forEach((item) => {
				if (item.save == 1) {
					obj[`${item.type}`] = item.on
				}
			})
			console.log(obj)
			this.putCompanyVars(obj)
		},
		putCompanyVars(obj) {
			//保存请求
			this.loading = true
			this.$axios({
				method: 'put',
				url: '/common1/companyVar/1',
				data: obj,
			})
				.then((res) => {
					this.loading = false
					// 重新获取变量
					this.getBudgetList()
					this.$message.success('保存成功')
				})
				.catch((err) => {
					this.loading = false
					this.isError(err)
					// console.log(err)
				})
		},
		recoveryEquip() {
			//恢复初始
			if (this.selEquipList.length == 0) {
				this.$message.warning('请勾选需要恢复初始的项')
				return
			}
			let obj = {}
			this.selEquipList.forEach((item) => {
				console.log(item)
				if (item.recovery == 1) {
					//可恢复初始判断
					obj[`${item.type}`] = 1
				}
			})
			this.$confirm('当前操作会使勾选项恢复初始设置,是否继续', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.loading = true
					this.activeName = ''
					this.$axios({
						method: 'put',
						url: '/common1/companyVar',
						data: obj,
					})
						.then(async (res) => {
							await this.getHotelAccount()
							setTimeout(() => {
								this.activeName = 'equipment'
								this.loading = false
								this.$message.success('恢复成功')
							}, 1000)
						})
						.catch((err) => {
							this.loading = false
							this.isError(err)
						})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消',
					})
				})
		},
	},
}
</script>
<style lang="scss">
.el-table {
	.success-row {
		background: rgba(88, 250, 130, 0.5);
	}
	.info-row {
		background: rgba(202, 218, 201, 0.5);
	}
	.hidden-row {
		overflow: hidden;
		display: none;
	}
}
.roomType {
	.el-input-group__append,
	.el-input-group__prepend {
		padding: 0 10px;
	}
}
</style>
<style lang="scss" scoped>
.center{
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin: 20px 0;
}
.input {
	.el-row {
		padding: 0 70px;
	}
	.el-divider--horizontal {
		margin: 30px 0 15px;
	}
	.el-divider__text {
		font-size: 16px;
		font-weight: 600;
	}
	.screenInput {
		width: 125px;
		>>> .el-textarea__inner {
			text-align: center;
		}
	}
}
</style>
